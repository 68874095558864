import React from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';

import './App.css';
import Layout from './components/Layout';
import { useUserState } from './context/UserContext';
import Error from './pages/error';
import Login from './pages/login';

export default function App() {
  var { isAuthenticated } = useUserState();
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/app/redirectPage" />} />
        <Route exact path="/app" render={() => <Redirect to="/app/redirectPage" />} />
        <PrivateRoute path="/app" component={Layout} />
        <PublicRoute path="/login" component={Login} />
        <Route component={Error} />
      </Switch>
    </HashRouter>
  );
  // #######################################################################
  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (React.createElement(component, props)) : 
            (<Redirect to={{ pathname: '/login', state: {from: props.location}}} />)
        }
      />
    );
  }
  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect to={{pathname: '/'}} />) : 
            (React.createElement(component, props))
        }
      />
    );
  }
}
