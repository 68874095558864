import axios from 'axios';
// import {config} from "../config"
import moment from 'moment';
import React from 'react';

const PolygonManagementContext = React.createContext();

const rootReducer = (state, action) => {
  switch (action.type) {
  case 'GET_DATA':
    return {
      ...state,
      isLoaded: true,
      summary: action.payload,
      error: ''
    };
  case 'GET_DATA_FILTERED':
    return {
      ...state,
      isLoaded: true,
      filtered: action.payload,
      error: ''
    };
  case 'GET_DATA_ERROR':
    return {
      ...state,
      isLoaded: true,
      summary: null,
      filtered: null,
      error: action.payload
    };
  case 'GET_DEVICES':
    return {
      ...state,
      devices: action.payload,
      devicesError: ''
    };
  case 'GET_DEVICES_ERROR':
    return {
      ...state,
      devices: null,
      devicesError: action.payload
    };
  case 'GET_LOCATIONS':
    return {
      ...state,
      isLoaded: true,
      locations: action.payload,
      error: ''
    };
  case 'GET_LOCATIONS_ERROR':
    return {
      ...state,
      isLoaded: true,
      locations: null,
      error: action.payload
    };
  case 'GET_DEVICE_FEEDS':
    return {
      ...state,
      feedList: action.payload,
      feedListError: ''
    };
  case 'GET_DEVICE_FEEDS_ERROR':
    return {
      ...state,
      feedList: null,
      feedListError: action.payload
    };
  case 'GET_ALL_FEED_DATA':
    return {
      ...state,
      allFeedData: action.payload,
      allFeedDataError: ''
    };
  case 'GET_ALL_FEED_DATA_ERROR':
    return {
      ...state,
      allFeedData: null,
      allFeedDataError: action.payload
    };
  case 'GET_DEVICE_DETAILS':
    return {
      ...state,
      deviceDetails: action.payload,
      deviceDetailsError: ''
    };
  case 'GET_DEVICE_DETAILS_ERROR':
    return {
      ...state,
      deviceDetails: null,
      deviceDetailsError: action.payload
    };
  case 'GET_DEVICE_DATAS':
    return {
      ...state,
      deviceDatas: action.payload,
      deviceDatasError: ''
    };
  case 'GET_DEVICE_DATAS_ERROR':
    return {
      ...state,
      deviceDatas: null,
      deviceDatasError: action.payload
    };
  case 'GET_FEED_DATA':
    return {
      ...state,
      feedData: action.payload,
      feedDataError: ''
    };
  case 'GET_FEED_DATA_ERROR':
    return {
      ...state,
      feedData: null,
      feedDataError: action.payload
    };
  case 'GET_PREVIOUS_DATA':
    return {
      ...state,
      pastData: action.payload,
      pastDataError: ''
    };
  case 'GET_PREVIOUS_DATA_ERROR':
    return {
      ...state,
      pastData: null,
      pastDataError: action.payload
    };
  case 'GET_PREVIOUS_DEVICE_DATA':
    return {
      ...state,
      previousDeviceData: action.payload,
      previousDeviceDataError: ''
    };
  case 'GET_PREVIOUS_DEVICE_DATA_ERROR':
    return {
      ...state,
      previousDeviceData: null,
      previousDeviceDataError: action.payload
    };
  case 'GET_ADDED_LOCATION_DATA':
    return {
      ...state,
      newLocation: action.payload,
      newLocationError: ''
    };
  case 'GET_ADDED_LOCATION_DATA_ERROR':
    return {
      ...state,
      newLocation: null,
      newLocationError: action.payload
    };
  case 'GET_PREVIOUS_DEVICE_SUMMARY_DATA':
    return {
      ...state,
      previousDeviceSummaryData: action.payload,
      previousDeviceSummaryDataError: ''
    };
  case 'GET_PREVIOUS_DEVICE_SUMMARY_DATA_ERROR':
    return {
      ...state,
      previousDeviceSummaryData: null,
      previousDeviceSummaryDataError: action.payload
    };
  case 'GET_PREVIOUS_DEVICE_SESSION_DATA':
    return {
      ...state,
      previousDeviceSessionData: action.payload,
      previousDeviceSessionDataError: ''
    };
  case 'GET_PREVIOUS_DEVICE_SESSION_DATA_ERROR':
    return {
      ...state,
      previousDeviceSessionData: null,
      previousDeviceSessionDataError: action.payload
    };
  case 'GET_PREVIOUS_STREAM_DATA':
    return {
      ...state,
      previousStreamData: action.payload,
      previousStreamDataError: ''
    };
  case 'GET_PREVIOUS_STREAM_DATA_ERROR':
    return {
      ...state,
      previousData: null,
      previousDataError: action.payload
    };
  case 'GET_MAP_DATA':
    return {
      ...state,
      mapData: action.payload,
      mapDataError: ''
    };
  case 'GET_MAP_DATA_ERROR':
    return {
      ...state,
      mapData: null,
      mapDataError: action.payload
    };
  default:
    return state;
  }
};

const PolygonManagementProvider = ({ children }) => {
  const [data, setData] = React.useReducer(rootReducer, {
    isLoaded: false,
    summary: null,
    filtered: null,
    locations: null,
    error: '',
    feedList: null,
    devices: null,
    feedListerror: ''
  });
  return (
    <PolygonManagementContext.Provider value={{ data, setData }}>
      {children}
    </PolygonManagementContext.Provider>
  );
};

const usePolygonManagementState = () => {
  const context = React.useContext(PolygonManagementContext);
  return context;
};

// export function getLocations(dispatch, locId) {

//     axios.get(`/${localStorage.getItem("organization")}/location?loc_cd=${locId}`).then(res => {

//         let locations = res.data.results.map((item, index) => {
//             // let randomContract = contractor;
//             item.geom.type = 'Polygon'
//             item.type = 'Polygon'
//             item.contractor = 'Contr'; //randomContract.contract
//             item.zone = 'SW'; //zoneArr[r]
//             item.lastCut = '2020=07-01';//locationSummary[item.loc_cd][' End'] //moment(`2020-06-${Math.floor(Math.random() * Math.floor(30))+1}`).format('DD-MM-YYYY')
//             item.quality = 'Bad'; //qualities[Math.floor(Math.random() * Math.floor(2))]
//             item.totalArea = 2;//locationSummary[item.loc_cd][' Total Area']
//             //item.grassCutPercent = Math.floor(Math.random() * Math.floor(100))
//             item.grassCutPercent = '-'
//             item.operationalhrs = '0.00' //locationSummary[item.loc_cd]['Duration'] //Math.floor(Math.random() * Math.floor(15))
//             let cornersLatLng = [[+Infinity, +Infinity], [-Infinity, -Infinity]];
//             let coordinates = item.geom.coordinates.map(item => {

//                 cornersLatLng = item[0].reduce((res, point) => {

//                     const pointsLong = point[0];
//                     const pointsLat = point[1];
//                     res[0][0] = pointsLong < res[0][0] ? pointsLong : res[0][0];
//                     res[0][1] = pointsLat < res[0][1] ? pointsLat : res[0][1];
//                     res[1][0] = pointsLong > res[1][0] ? pointsLong : res[1][0];
//                     res[1][1] = pointsLat > res[1][1] ? pointsLat : res[1][1];

//                     return res;
//                 }, cornersLatLng)

//                 return item[0]
//             })
//             item.cornersLatLng = cornersLatLng;
//             return {
//                 type: 'Feature',
//                 properties: item,
//                 geometry: {
//                     type: 'Polygon',
//                     coordinates: coordinates
//                 }
//             }
//         });

//         dispatch({ type: "GET_LOCATIONS", payload: locations });
//     }).catch(err => {
//         dispatch({ type: "GET_LOCATIONS_ERROR", payload: err });
//     });

// }
export function getDevices(dispatch) {
  return axios.get(`/${localStorage.getItem('organization')}/devices`).then(res => {
    dispatch({ type: 'GET_DEVICES', payload: res.data });
  }).catch(err => {
    dispatch({ type: 'GET_DEVICES_ERROR', payload: err });
  });
}
export function addLocation(dispatch, inputData) {
  return axios.post(`${localStorage.getItem('organization')}/locations`,inputData).then(res => {
    // console.log(res,'res')
    dispatch({ type: 'GET_ADDED_LOCATION_DATA', payload: res.data });
  }).catch(err => {
    dispatch({ type: 'GET_ADDED_LOCATION_DATA_ERROR', payload: err });
  });
}

export function getDeviceFeeds(dispatch, deviceId) {
  return axios.get(`/${localStorage.getItem('organization')}/feeds?device__device_id=${deviceId}`).then(res => {
    dispatch({ type: 'GET_DEVICE_FEEDS', payload: res.data });
  }).catch(err => {
    dispatch({ type: 'GET_DEVICE_FEEDS_ERROR', payload: err });
  });
}

export function getDeviceDetails(dispatch, deviceId) {
  return axios.get(`/${localStorage.getItem('organization')}/devices/${deviceId}`).then(res => {
    dispatch({ type: 'GET_DEVICE_DETAILS', payload: res.data });
  }).catch(err => {
    dispatch({ type: 'GET_DEVICE_DETAILS_ERROR', payload: err });
  });
}
export function getDeviceData(dispatch, deviceId, selectedDate) {
  var startDate = moment(selectedDate).format('YYYY-MM-DD');
  var endDate = moment(selectedDate).format('YYYY-MM-DD');
  var startTime = `${startDate  } 00:00`;
  var endTime = `${endDate  } 23:59`;

  var utcstartDate = moment(startTime).utc().format('YYYY-MM-DD HH:mm:ss');
  var utcendDate = moment(endTime).utc().format('YYYY-MM-DD HH:mm:ss');
  return axios.get(`/${localStorage.getItem('organization')}/devices/${deviceId}/device-data?completed_at__range=${utcstartDate}, ${utcendDate}`).then(res => {
    dispatch({ type: 'GET_DEVICE_DATAS', payload: res.data });
  }).catch(err => {
    dispatch({ type: 'GET_DEVICE_DATAS_ERROR', payload: err });
  });
}

export function getFeedData(dispatch, deviceId) {
  var startDate = moment().format('YYYY-MM-DD');
  var endDate = moment().format('YYYY-MM-DD');
  var startTime = `${startDate  } 00:00`;
  var endTime = `${endDate  } 23:59`;
  let mapData = [];
  var utcstartDate = moment(startTime).utc().format('YYYY-MM-DD HH:mm:ss');
  var utcendDate = moment(endTime).utc().format('YYYY-MM-DD HH:mm:ss');

  let offsetVal = 0;
  axios.get(`/${localStorage.getItem('organization')}/devices/${deviceId}/stream-data-full?completed_at__range=${utcstartDate},${utcendDate}&limit=3000&offset=${offsetVal}`, { headers: { accept: 'application/json' } }).then(res => {
    let totalCount = res.data.count;
    let noofLoops = (totalCount / 3000) - 1;
    let streamdatareq = [];
    let streamdatares = [];
    streamdatares.push(res.data.results);
    if(noofLoops > 1) {
      let loopInt = Number(noofLoops.toFixed(0));
      let loopDecimal = Number(noofLoops.toFixed(1));
      let forloops = loopInt;
      if(loopDecimal > loopInt) {
        forloops = (Number(loopInt) + 1);
      }
      for(let j = 0; j < forloops;j++) {
        offsetVal = offsetVal + 3000;
        streamdatareq.push(axios.get(`/${localStorage.getItem('organization')}/devices/${deviceId}/stream-data-full?completed_at__range=${utcstartDate},${utcendDate}&limit=3000&offset=${offsetVal}`));
      }
      axios.all(streamdatareq).then(axios.spread((...streamResp) => {
        streamResp.forEach(s=>{
          streamdatares.push(s.data.results);
        });     
        if(streamdatares.length > 1) {
          var allDataArr = [].concat.apply([], streamdatares);
          mapData = allDataArr;
          dispatch({ type: 'GET_FEED_DATA', payload: mapData });
        }    
      }));                   
    }
    else{
      mapData = res.data.results;
      // let isDataFound = mapData && mapData.length > 0;
      dispatch({ type: 'GET_FEED_DATA', payload: mapData });
    }            
  }).catch(err => {
    dispatch({ type: 'GET_FEED_DATA_ERROR', payload: 'No data found' });
  });
}
// export function getPreviousData(dispatch, deviceId) {
//     return axios.get(`/${localStorage.getItem("organization")}/get-processed-data/?device=${deviceId}`).then(res => {
//         dispatch({ type: "GET_PREVIOUS_DATA", payload: res.data });
//     }).catch(err => {
//         dispatch({ type: "GET_PREVIOUS_DATA_ERROR", payload: err });
//     });
// }
export function getPreviousData(dispatch, deviceUUID, device_id, selectedDate) {
  return axios.get(`/${localStorage.getItem('organization')}/location-processed-data?file_created_at=${selectedDate}&device__device_id=${device_id}`).then(res => {
    // let fData = res.data.results.filter(l => {
    //     return l.device === deviceId
    // });
    let tData = res.data.results.filter(t => {
      // let dId = ''
      if (t.file_type === 'stream_data' || t.file_type === 'device_data' || t.file_type === 'device_summary' || t.file_type === 'device_session_summary') {
        if (t.device) {
          // dId = t.device;
          if (t.device === deviceUUID) return true;
        } else {
          // dId = t.file_name.split('_')[0]
          if (t.file_name.split('_')[0] === device_id) return true;
        }
      }
      // return deviceId === t.device && t.file_type === 'trim_data'
      return false;
    });
    dispatch({ type: 'GET_PREVIOUS_DATA', payload: tData });
  }).catch(err => {
    dispatch({ type: 'GET_PREVIOUS_DATA_ERROR', payload: err });
  });
}
export function getPreviousDeviceData(dispatch, itemId) {
  return axios.get(`/${localStorage.getItem('organization')}/location-processed-data/${itemId}`).then(res => {
    dispatch({ type: 'GET_PREVIOUS_DEVICE_DATA', payload: res.data });
  }).catch(err => {
    dispatch({ type: 'GET_PREVIOUS_DEVICE_DATA_ERROR', payload: err });
  });
}
export function getPreviousDeviceSessionData(dispatch, itemId) {
  return axios.get(`/${localStorage.getItem('organization')}/location-processed-data/${itemId}`).then(res => {
    dispatch({ type: 'GET_PREVIOUS_DEVICE_SESSION_DATA', payload: res.data });
  }).catch(err => {
    dispatch({ type: 'GET_PREVIOUS_DEVICE_SESSION_DATA_ERROR', payload: err });
  });
}
export function getPreviousDeviceSummaryData(dispatch, itemId) {
  //return axios.post(`trim/s3/`,inputData).then(res => {
  return axios.get(`/${localStorage.getItem('organization')}/location-processed-data/${itemId}`).then(res => {
    dispatch({ type: 'GET_PREVIOUS_DEVICE_SUMMARY_DATA', payload: res.data });
  }).catch(err => {
    dispatch({ type: 'GET_PREVIOUS_DEVICE_SUMMARY_DATA_ERROR', payload: err });
  });
}

export function getPreviousStreamData(dispatch, itemId) {
  return axios.get(`/${localStorage.getItem('organization')}/location-processed-data/${itemId}`).then(res => {
    dispatch({ type: 'GET_PREVIOUS_STREAM_DATA', payload: res.data });
  }).catch(err => {
    dispatch({ type: 'GET_PREVIOUS_STREAM_DATA_ERROR', payload: err });
  });
}

export function getMapData(dispatch, deviceId) {
  return axios.get(`/${localStorage.getItem('organization')}/devices/${deviceId}/chart-data-stat/15/12`).then(res => {
    dispatch({ type: 'GET_MAP_DATA', payload: res.data });
  }).catch(err => {
    dispatch({ type: 'GET_MAP_DATA_ERROR', payload: err });
  });
}

export function getLocations(dispatch, staticLocations) {

  return axios.get(`/${localStorage.getItem('organization')}/locations?key=${staticLocations.join(',')}`).then(res => {

    let locations = res.data.results.features.map((item, index) => {
      // item.geometry.type = 'Polygon'
      item.type = 'Polygon';
      // item.properties.zone = zoneArr[r]
      let coordinates = item.geometry.coordinates.map(item => {
        return item[0];
      });
      if (item.bbox && item.bbox.length === 4) {
        item.properties.cornersLatLng = [[item.bbox[0], item.bbox[1]], [item.bbox[2], item.bbox[3]]];
      }
      return {
        ...item,
        type: 'Feature',
        // properties: item,
        geometry: {
          type: item.geometry.type, //'Polygon',
          coordinates
        }
      };
    });

    dispatch({ type: 'GET_LOCATIONS', payload: locations });

  }).catch(err => {
    dispatch({ type: 'GET_LOCATIONS_ERROR', payload: err });
  });

  // let reqArr = [];
  // let zoneArr = [];
  // for (var zone in staticLocations) {
  //     zoneArr.push(zone);
  //     reqArr.push(axios.get(`/${localStorage.getItem("organization")}/location?loc_cd=${staticLocations[zone].join(',')}`).catch(err => {
  //         //console.log('loc api error', err);
  //     }))
  // }

  // axios.all(reqArr).then(axios.spread((...responses) => {
  //     let locations = []
  //     //console.log('responses', responses);
  //     for (let r = 0; r < responses.length; r++) {
  //         if (responses[r] && responses[r].data && responses[r].data.results && responses[r].data.results.features) {
  //             // let contractor = ''; //(contractorsArr.filter(c => c.zone == zoneArr[r]))[0];
  //             //console.log('responses[r].data.results', responses[r].data.results);
  //             let mJson = responses[r].data.results.features.map((item, index) => {
  //                 item.geometry.type = 'Polygon'
  //                 item.type = 'Polygon'
  //                 item.properties.zone = zoneArr[r]
  //                 let coordinates = item.geometry.coordinates.map(item => {
  //                     return item[0];
  //                 });
  //                 if (item.bbox && item.bbox.length === 4) {
  //                     item.properties.cornersLatLng = [[item.bbox[0], item.bbox[1]], [item.bbox[2], item.bbox[3]]];
  //                 }
  //                 return {
  //                     ...item,
  //                     type: 'Feature',
  //                     // properties: item,
  //                     geometry: {
  //                         type: 'Polygon',
  //                         coordinates: coordinates
  //                     }
  //                 }
  //             });
  //             locations = locations.concat(mJson);
  //             //console.log(locations,'locs')
  //         }
  //     }
  //     dispatch({ type: "GET_LOCATIONS", payload: locations });
  // }))
}

export function getAllFeedData(dispatch, feedList, startTime, endTime) {
  // return axios.get(`/${localStorage.getItem("organization")}/devices`).then(res => {
  // dispatch({ type: "UPDATE_DEVICES", payload: res.data });

  // let devicedata = feedList.results;
  if (feedList) {
    let reqArr = [];
    for (let i = 0; i < feedList.length; i++) {
      // getFeeds(devicedata[i].device_id)
      reqArr.push(axios.get(`/${localStorage.getItem('organization')}/feeds/${feedList[i].key}/data?completed_at__range=${startTime},${endTime}&limit=2999`).catch(err => {

      }));
    }
    axios.all(reqArr).then(axios.spread((...responses) => {

      // let gpsdevices = []
      // for (let r = 0; r < responses.length; r++) {
      //     let res = responses[r];
      //     for (let f = 0; f < res.data.results.length; f++) {
      //         if (res.data.results[f].category == "gps" && devicedata[r].feed_status && res.data.results[f].feed_data.length > 0) {
      //             //action.payload.data.results[f].feed_data[0].value
      //             let device = devicedata[r];
      //             device.devicelocation = JSON.parse(res.data.results[f].feed_data[0].value);

      //             gpsdevices.push(device);
      //         }
      //     }
      // }
      let rData = responses.map(r => r.data.results);
      //console.log('rData', rData);
      dispatch({ type: 'GET_ALL_FEED_DATA', payload: rData });
      // console.log('devicedata', gpsdevices)
    }));
  }

  // }).catch(err => {
  //     // signOut(userDispatch,history);
  //     console.log(err);
  // });
}

export { PolygonManagementProvider, PolygonManagementContext, usePolygonManagementState };