import axios from 'axios';
import React from 'react';

var LayoutStateContext = React.createContext();
var LayoutDispatchContext = React.createContext();

function layoutReducer(state, action) {
  switch (action.type) {
  case 'TOGGLE_SIDEBAR':
    return { ...state, isSidebarOpened: !state.isSidebarOpened };
  case 'GET_DEVICES_HEALTH':
    return {
      ...state,
      devicesHealth: action.payload,
      devicesHealthError: ''
    };
  case 'GET_DEVICES_HEALTH_ERROR':
    return {
      ...state,
      devicesHealth: null,
      devicesHealthError: action.payload
    };
  case 'GET_DASHBOARD_SETTINGS':
    return {
      ...state,
      dashboardSettings: action.payload,
      dashboardSettingsError: ''
    };
  case 'GET_DASHBOARD_LIST':
    return {
      ...state,
      dashboardList: action.payload,
      dashboardListError: ''
    };
  case 'GET_DASHBOARD_SETTINGS_ERROR':
    return {
      ...state,
      dashboardSettings: null,
      dashboardSettingsError: action.payload
    };
  default: {
    throw new Error(`Unhandled action type: ${action.type}`);
  }
  }
}

var LayoutProvider = ({ children }) => {
  var [state, dispatch] = React.useReducer(layoutReducer, {
    isSidebarOpened: false
  });
  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>
        {children}
      </LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  );
};

function useLayoutState() {
  var context = React.useContext(LayoutStateContext);
  if (context === undefined) {
    throw new Error('useLayoutState must be used within a LayoutProvider');
  }
  return context;
}

function useLayoutDispatch() {
  var context = React.useContext(LayoutDispatchContext);
  if (context === undefined) {
    throw new Error('useLayoutDispatch must be used within a LayoutProvider');
  }
  return context;
}

export function getDashboardSettings(dispatch) {
  return axios.get(`/${localStorage.getItem('organization')}/dashboards`).then(res => {
    let dList = res.data.results;
    dispatch({ type: 'GET_DASHBOARD_LIST', payload: dList });
    let configDashboard = dList.filter(d=>d.data && d.data.custom_config).map(d => {
      return d.data.custom_config;
    });
    if (configDashboard && configDashboard.length > 0) {
      getDevicesHealthWithDashboard(dispatch, configDashboard[0]);
      dispatch({ type: 'GET_DASHBOARD_SETTINGS', payload: configDashboard[0] });
    }
    else {
      getDevicesHealthWithDashboard(dispatch, null);
      dispatch({ type: 'GET_DASHBOARD_SETTINGS', payload: null });
    }
  }).catch(err => {
    dispatch({ type: 'GET_DEVICES_HEALTH_ERROR', payload: err });
  });
}

export function getDevicesHealth(dispatch) {
  let isEnterpriseUser = localStorage.getItem('isEnterpriseUser');
  let enterpriseOrg = localStorage.getItem('enterpriseOrganization');
  let activeDevices = 0;
  let alertDevices = 0;
  let inactiveDevices = 0;
  let devicesHealthCount = { active: activeDevices, alert: alertDevices, inactive: inactiveDevices };
  if(isEnterpriseUser === 'true') {
    let reqArr = [];
    return axios.get(`/${enterpriseOrg}/enterprise-org`).then(res => {
      let enterpriseOrgs = res.data.results;
      reqArr.push(axios.get(`/${enterpriseOrg}/devices-health/`));
      if(enterpriseOrgs && enterpriseOrgs.length > 0) {
        enterpriseOrgs.forEach(e=>{
          reqArr.push(axios.get(`/${e.key}/devices-health/`));
        });
      }
      axios.all(reqArr).then(axios.spread((...responses) => {
        responses.forEach((device, i) => {
          let deviceResp = device.data;
          activeDevices += deviceResp.active;
          alertDevices += deviceResp.alert;
          inactiveDevices += deviceResp.inactive  + deviceResp.unsubscribed;
        });
        devicesHealthCount = { active: activeDevices, alert: alertDevices, inactive: inactiveDevices };
        dispatch({ type: 'GET_DEVICES_HEALTH', payload: devicesHealthCount });
      }));
    }).catch(err => {
      dispatch({ type: 'GET_DEVICES_HEALTH_ERROR', payload: err });
    });
  } 
  return axios.get(`/${localStorage.getItem('organization')}/devices-health/`).then(res => {
    let resp = res.data;
    let devicesHealth = resp;
    dispatch({ type: 'GET_DEVICES_HEALTH', payload: devicesHealth });
  }).catch(err => {
    dispatch({ type: 'GET_DEVICES_HEALTH_ERROR', payload: err });
  });
   
}

export function getDevicesHealthWithDashboard(dispatch, devices) {
  let devicesTags = devices && devices.devices_with_tags && devices.devices_with_tags.length;
  if (devicesTags > 0) {
    return axios.get(`/${localStorage.getItem('organization')}/devices`).then(res => {
      let resp = res.data.results;
      let activeCount = 0;
      let alertCount = 0;
      let inactiveCount = 0;
      let filteredDevices = [];
      let devicesHealth = { active: activeCount, alert: alertCount, inactive: inactiveCount };
      if (resp.length > 0) {
        resp.forEach(d => {
          if (devices && devices.devices_with_tags.length > 0) {
            if (d.tags.length > 0) {
              d.tags.forEach(d1 => {
                devices.devices_with_tags.forEach(c => {
                  if (d1 === c) {
                    filteredDevices.push(d);
                  }
                });
              });
            }
          }
          else {
            if (d.is_active === true) {
              if (d.feed_status && d.feed_status === 'Inactive') {
                inactiveCount = inactiveCount + 1;
              }
              else if (d.feed_status && d.feed_status === 'Norm') {
                activeCount = activeCount + 1;
              }
              else if (d.feed_status && d.feed_status == null) {
                inactiveCount = inactiveCount + 1;
              }
              else if (d.feed_status && d.feed_status === 'Session') {
                activeCount = activeCount + 1;
              }
              else {
                alertCount = alertCount + 1;
              }
            }
            else {
              inactiveCount = inactiveCount + 1;
            }
          }
        });

        filteredDevices && filteredDevices.forEach(fd => {
          if (fd.is_active === true) {
            if (fd.feed_status && fd.feed_status === 'Inactive') {
              inactiveCount = inactiveCount + 1;
            }
            else if (fd.feed_status && fd.feed_status === 'Norm') {
              activeCount = activeCount + 1;
            }
            else if (fd.feed_status && fd.feed_status == null) {
              inactiveCount = inactiveCount + 1;
            }
            else if (fd.feed_status && fd.feed_status === 'Session') {
              activeCount = activeCount + 1;
            }
            else {
              alertCount = alertCount + 1;
            }
          }
          else {
            inactiveCount = inactiveCount + 1;
          }
        });
        devicesHealth = { active: activeCount, alert: alertCount, inactive: inactiveCount };
      }
      dispatch({ type: 'GET_DEVICES_HEALTH', payload: devicesHealth });
    }).catch(err => {
      dispatch({ type: 'GET_DEVICES_HEALTH_ERROR', payload: err });
    });
  }
  
  let isEnterpriseUser = localStorage.getItem('isEnterpriseUser');
  let enterpriseOrg = localStorage.getItem('enterpriseOrganization');
  let activeDevices = 0;
  let alertDevices = 0;
  let inactiveDevices = 0;
  let devicesHealthCount = { active: activeDevices, alert: alertDevices, inactive: inactiveDevices };
  if(isEnterpriseUser === 'true') {
    let reqArr = [];
    return axios.get(`/${enterpriseOrg}/enterprise-org`).then(res => {
      let enterpriseOrgs = res.data.results;
      reqArr.push(axios.get(`/${enterpriseOrg}/devices-health/`));
      if(enterpriseOrgs && enterpriseOrgs.length > 0) {
        enterpriseOrgs.forEach(e=>{
          reqArr.push(axios.get(`/${e.key}/devices-health/`));
        });
      }
      axios.all(reqArr).then(axios.spread((...responses) => {
        responses.forEach((device, i) => {
          let deviceResp = device.data;
          activeDevices += deviceResp.active;
          alertDevices += deviceResp.alert;
          inactiveDevices += deviceResp.inactive  + deviceResp.unsubscribed;
        });
        devicesHealthCount = { active: activeDevices, alert: alertDevices, inactive: inactiveDevices };
        dispatch({ type: 'GET_DEVICES_HEALTH', payload: devicesHealthCount });
      }));
    }).catch(err => {
      dispatch({ type: 'GET_DEVICES_HEALTH_ERROR', payload: err });
    });
  } 
  return axios.get(`/${localStorage.getItem('organization')}/devices-health/`).then(res => {
    let resp = res.data;
    let devicesHealth = resp;
    dispatch({ type: 'GET_DEVICES_HEALTH', payload: devicesHealth });
  }).catch(err => {
    dispatch({ type: 'GET_DEVICES_HEALTH_ERROR', payload: err });
  });
  
}

export { LayoutProvider, useLayoutState, useLayoutDispatch, toggleSidebar };

// ###########################################################
function toggleSidebar(dispatch) {
  dispatch({
    type: 'TOGGLE_SIDEBAR',
  });
}
