import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';

import { APIStatus } from '../APIStatus';
import { revertAll } from '../globalSlice';

const initialState = {
  feedDataStatus: APIStatus.idle,
  feedData: null,
  feedDataError: null,
  deviceDataStatus: APIStatus.idle,
  deviceData: null,
  deviceDataError: null,
  previousDataStatus: APIStatus.idle,
  previousData: null,
  previousDataError: null,
  previousStreamDataStatus: APIStatus.idle,
  previousStreamData: null,
  previousStreamDataError: null,
  previousDeviceDataStatus: APIStatus.idle,
  previousDeviceData: null,
  previousDeviceDataError: null,
  previousDeviceSummaryDataStatus: APIStatus.idle,
  previousDeviceSummaryData: null,
  previousDeviceSummaryDataError: null,
  previousDeviceSessionDataStatus: APIStatus.idle,
  previousDeviceSessionData: null,
  previousDeviceSessionDataError: null,
  deviceSessionsStatus: APIStatus.idle,
  deviceSessions: null,
  deviceSessionsError: null,
  deviceFeedsStatus: APIStatus.idle,
  deviceFeeds: null,
  deviceFeedsError: null,
  addFeedStatus: APIStatus.idle,
  addFeedReceipt: null,
  addFeedError: null,
  updateFeedStatus: APIStatus.idle,
  updateFeedReceipt: null,
  updateFeedError: null,
  deleteFeedStatus: APIStatus.idle,
  deleteFeedReceipt: null,
  deleteFeedError: null,
  updatedFeedRulesStatus: APIStatus.idle,
  updatedFeedRulesReceipt: null,
  updatedFeedRulesError: null,
};

export const getFeedData = createAsyncThunk('feedsSlice/getFeedData', async({ deviceId, selectedOrg }) => {
  var startDate = moment().format('YYYY-MM-DD');
  var endDate = moment().format('YYYY-MM-DD');
  var startTime = `${startDate} 00:00`;
  var endTime = `${endDate} 23:59`;
  let mapData = [];
  var utcstartDate = moment(startTime).utc().format('YYYY-MM-DD HH:mm:ss');
  var utcendDate = moment(endTime).utc().format('YYYY-MM-DD HH:mm:ss');

  let offsetVal = 0;
  const response = await axios.get(`/${selectedOrg}/devices/${deviceId}/stream-data-full?completed_at__range=${utcstartDate},${utcendDate}&limit=3000&offset=${offsetVal}`, { headers: { accept: 'application/json' } });

  let totalCount = response.data.count;
  let noofLoops = (totalCount / 3000);
  let streamdatareq = [];
  let streamdatares = [];
  streamdatares.push(response.data.results);
  if (noofLoops > 1) {
    let loopInt = Number(noofLoops.toFixed(0));
    let forloops = loopInt;
    for (let j = 0; j < forloops; j++) {
      offsetVal = offsetVal + 3000;
      streamdatareq.push(axios.get(`/${selectedOrg}/devices/${deviceId}/stream-data-full?completed_at__range=${utcstartDate},${utcendDate}&limit=3000&offset=${offsetVal}`));
    }
    const streamResp = await axios.all(streamdatareq);

    streamResp.forEach(s => {
      streamdatares.push(s.data.results);
    });
    if (streamdatares.length > 1) {
      var allDataArr = [].concat.apply([], streamdatares);
      mapData = allDataArr;
      return mapData;
    }

  }
  else {
    mapData = response.data.results;
    return mapData;
  }
});

export const getDeviceData = createAsyncThunk('feedsSlice/getDeviceData', async({ deviceId, selectedDate, selectedOrg, offset }) => {
  const startDate = moment(selectedDate).format('YYYY-MM-DD');
  const endDate = moment(selectedDate).format('YYYY-MM-DD');
  const startTime = `${startDate} 00:00`;
  const endTime = `${endDate} 23:59`;
  const limit  = 100;
  const utcstartDate = moment(startTime).utc().format('YYYY-MM-DD HH:mm:ss');
  const utcendDate = moment(endTime).utc().format('YYYY-MM-DD HH:mm:ss');
  const response = await axios.get(`/${selectedOrg}/devices/${deviceId}/device-data`,
    {params: {
      completed_at__range: `${utcstartDate}, ${utcendDate}`,
      limit,
      offset: offset ? (limit * offset) : 0
    }});
  return response.data;
});

export const getPreviousData = createAsyncThunk('feedsSlice/getPreviousData', async({ deviceUUID, device_id, selectedDate, selectedOrg }) => {
  const response = await axios.get(`/${selectedOrg}/location-processed-data?file_created_at=${selectedDate}&device__device_id=${device_id}`);

  let tData = response.data.results.filter(t => {
    if (t.file_type === 'stream_data' || t.file_type === 'device_data' || t.file_type === 'D-stats' || t.file_type === 'DS-work-gis') {
      if (t.device) {
        if (t.device === deviceUUID) return true;
      } else {
        if (t.file_name.split('_')[0] === device_id) return true;
      }
    }
    return false;
  });
  return tData;
});

export const getPreviousStreamData = createAsyncThunk('feedsSlice/getPreviousStreamData', async({ itemId, selectedOrg }) => {
  const response = await axios.get(`/${selectedOrg}/location-processed-data/${itemId}`);
  return response.data;
});

export const getPreviousDeviceData = createAsyncThunk('feedsSlice/getPreviousDeviceData', async({ itemId, selectedOrg }) => {
  const response = await axios.get(`/${selectedOrg}/location-processed-data/${itemId}`);
  return response.data;
});

export const getPreviousDeviceSummaryData = createAsyncThunk('feedsSlice/getPreviousDeviceSummaryData', async({ itemId, selectedOrg }) => {
  const response = await axios.get(`/${selectedOrg}/location-processed-data/${itemId}`);
  return response.data;
});

export const getPreviousDeviceSessionData = createAsyncThunk('feedsSlice/getPreviousDeviceSessionData', async({ itemId, selectedOrg }) => {
  const response = await axios.get(`/${selectedOrg}/location-processed-data/${itemId}`);
  return response.data;
});

export const getDeviceSessions = createAsyncThunk('feedsSlice/getDeviceSessions', async({ dateRange }) => {

  let reqArr = [];
  let reqDetailArr = [];
  let noOfDays = moment(dateRange[1]).diff(dateRange[0], 'days') + 1;
  let startDate = moment(dateRange[0]);
  let isToday = startDate.isSame(moment(), 'day');
  let dateListArr = calculateDaysBetweenDates(moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD'));
  if (isToday) {
    return null;
  } else if (noOfDays === 1) {
    let startdateQuery = startDate.format('YYYY-MM-DD');
    reqArr.push(axios.get(`/${localStorage.getItem('organization')}/location-processed-data?file_created_at=${startdateQuery}&file_type=AS-work-summary`)
      .then(res => {
        let fData = res.data.results.filter(l => {
          return l.file_type === 'AS-work-summary' && l.file_name.includes('AS-work-summary');
        });
        return fData;
      })
      .catch(err => {
        return null;
      }));
  }
  else {
    dateListArr.forEach((date, index) => {
      reqArr.push(axios.get(`/${localStorage.getItem('organization')}/location-processed-data?file_created_at=${date}&file_type=AS-work-summary`)
        .then(res => {
          let fData = res.data.results.filter(l => {
            return l.file_type === 'AS-work-summary' && l.file_name.includes('AS-work-summary');
          });
          return fData;
        })
        .catch(err => {
          return null;
        }));
    });

  }

  const responses = await axios.all(reqArr);

  let resultArr = [];
  for (let r = 0; r < responses.length; r++) {
    if (responses[r].length > 0) {
      var detailResp = responses[r];
      if (detailResp) {
        for (let i = 0; i < detailResp.length; i++) {
          reqDetailArr.push(axios.get(`/${localStorage.getItem('organization')}/location-processed-data/${detailResp[i].id}`)
            .then(res => {
              return res.data;
            })
            .catch(err => {
              return null;
            }));
        }
      }

    }
  }
  const detailResponses = await axios.all(reqDetailArr);

  if (detailResponses) {
    for (let d = 0; d < detailResponses.length; d++) {
      var detailData = detailResponses[d];
      if (detailData) {
        resultArr.push(detailData.file_s3_url);
      }
    }
    return resultArr;
  }
});

export const addFeed = createAsyncThunk('addFeed', async({ organization, data }) => {
  const response = await axios.post(`/${organization}/feeds`, data);
  return response;
});

export const updateFeed = createAsyncThunk('updateFeed', async({ organization, id, data }) => {
  const response = await axios.patch(`/${organization}/feeds/${id}`, data);
  return response;
});

export const deleteFeed = createAsyncThunk('deleteFeed', async({ organization, id }) => {
  const response = await axios.delete(`/${organization}/feeds/${id}`);
  return response;
});

function calculateDaysBetweenDates(startDate, endDate) {
  let date = [];
  while (moment(startDate) <= moment(endDate)) {
    date.push(startDate);
    startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD');
  }
  return date;
}

export const getDeviceFeedsAndRules = createAsyncThunk('getDeviceFeedsAndRules', async({ organization, id }) => {
  const feeds = await axios.get(`/${organization}/feeds?device__device_id=${id}`);
  const feedResp = feeds.data.results;
  const reqArr = feedResp && feedResp.map(f => {
    return axios.get(`/${organization}/feeds/rules/?feed=${f.id}`);
  });
  const response = await axios.all(reqArr).then(axios.spread((...responses) => {
    feedResp.forEach((feed, i) => {
      if (!feed.feedRules) {
        feed.feedRules = (responses[i] && responses[i].data) ? responses[i].data.results : [];
      }
    });
    return feedResp;
  }));
  return response;
});

export const getUpdatedFeedRules = createAsyncThunk('getUpdatedFeedRules', async({ organization, id }) => {
  const response = await axios.get(`/${organization}/feeds/rules/?feed=${id}`);
  return response.data;
});

export const getDeviceFeeds = createAsyncThunk('getDeviceFeeds', async({ organization, id }) => {
  const response = await axios.get(`/${organization}/feeds?device__device_id=${id}`);
  return response;
});

const feedsSlice = createSlice({
  name: 'feeds',
  initialState,
  reducers: {
    reset(state) {
      state = { ...initialState };
    },
    resetAddFeed(state, action) {
      state.addFeedStatus = APIStatus.idle;
      state.addFeedReceipt = null;
      state.addFeedError = null;
    },
    resetUpdateFeed(state, action) {
      state.updateFeedStatus = APIStatus.idle;
      state.updateFeedReceipt = null;
      state.updateFeedError = null;
    },
    resetDeviceSummary(state) {
      state.feedDataStatus = APIStatus.idle;
      state.feedData = null;
      state.feedDataError = null;
      state.deviceDataStatus = APIStatus.idle;
      state.deviceData = null;
      state.deviceDataError = null;
      state.previousDataStatus = APIStatus.idle;
      state.previousData = null;
      state.previousDataError = null;
      state.previousStreamDataStatus = APIStatus.idle;
      state.previousStreamData = null;
      state.previousStreamDataError = null;
      state.previousDeviceDataStatus = APIStatus.idle;
      state.previousDeviceData = null;
      state.previousDeviceDataError = null;
      state.previousDeviceSummaryDataStatus = APIStatus.idle;
      state.previousDeviceSummaryData = null;
      state.previousDeviceSummaryDataError = null;
      state.previousDeviceSessionDataStatus = APIStatus.idle;
      state.previousDeviceSessionData = null;
      state.previousDeviceSessionDataError = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(getFeedData.pending, (state, action) => {
        state.feedDataStatus = APIStatus.loading;
        state.feedData = null;
      })
      .addCase(getFeedData.fulfilled, (state, action) => {
        state.feedDataStatus = APIStatus.loaded;
        state.feedData = action.payload;
      })
      .addCase(getFeedData.rejected, (state, action) => {
        state.feedDataStatus = APIStatus.failed;
        state.feedDataError = action.error;
      })
      .addCase(getDeviceData.pending, (state, action) => {
        state.deviceDataStatus = APIStatus.loading;
        state.deviceData = null;
      })
      .addCase(getDeviceData.fulfilled, (state, action) => {
        state.deviceDataStatus = APIStatus.loaded;
        state.deviceData = action.payload;
      })
      .addCase(getDeviceData.rejected, (state, action) => {
        state.deviceDataStatus = APIStatus.failed;
        state.deviceDataError = action.error;
      })
      .addCase(getPreviousData.pending, (state, action) => {
        state.previousDataStatus = APIStatus.loading;
        state.previousData = null;
      })
      .addCase(getPreviousData.fulfilled, (state, action) => {
        state.previousDataStatus = APIStatus.loaded;
        state.previousData = action.payload;
      })
      .addCase(getPreviousData.rejected, (state, action) => {
        state.previousDataStatus = APIStatus.failed;
        state.previousDataError = action.error;
      })
      .addCase(getPreviousStreamData.pending, (state, action) => {
        state.previousStreamDataStatus = APIStatus.loading;
        state.previousStreamData = null;
      })
      .addCase(getPreviousStreamData.fulfilled, (state, action) => {
        state.previousStreamDataStatus = APIStatus.loaded;
        state.previousStreamData = action.payload;
      })
      .addCase(getPreviousStreamData.rejected, (state, action) => {
        state.previousStreamDataStatus = APIStatus.failed;
        state.previousStreamDataError = action.error;
      })
      .addCase(getPreviousDeviceData.pending, (state, action) => {
        state.previousDeviceDataStatus = APIStatus.loading;
        state.previousDeviceData = null;
      })
      .addCase(getPreviousDeviceData.fulfilled, (state, action) => {
        state.previousDeviceDataStatus = APIStatus.loaded;
        state.previousDeviceData = action.payload;
      })
      .addCase(getPreviousDeviceData.rejected, (state, action) => {
        state.previousDeviceDataStatus = APIStatus.failed;
        state.previousDeviceDataError = action.error;
      })
      .addCase(getPreviousDeviceSummaryData.pending, (state, action) => {
        state.previousDeviceSummaryDataStatus = APIStatus.loading;
        state.previousDeviceSummaryData = null;
      })
      .addCase(getPreviousDeviceSummaryData.fulfilled, (state, action) => {
        state.previousDeviceSummaryDataStatus = APIStatus.loaded;
        state.previousDeviceSummaryData = action.payload;
      })
      .addCase(getPreviousDeviceSummaryData.rejected, (state, action) => {
        state.previousDeviceSummaryDataStatus = APIStatus.failed;
        state.previousDeviceSummaryDataError = action.error;
      })
      .addCase(getPreviousDeviceSessionData.pending, (state, action) => {
        state.previousDeviceSessionDataStatus = APIStatus.loading;
        state.previousDeviceSessionData = null;
      })
      .addCase(getPreviousDeviceSessionData.fulfilled, (state, action) => {
        state.previousDeviceSessionDataStatus = APIStatus.loaded;
        state.previousDeviceSessionData = action.payload;
      })
      .addCase(getPreviousDeviceSessionData.rejected, (state, action) => {
        state.previousDeviceSessionDataStatus = APIStatus.failed;
        state.previousDeviceSessionDataError = action.error;
      })
      .addCase(getDeviceSessions.pending, (state, action) => {
        state.deviceSessionsStatus = APIStatus.loading;
        state.deviceSessions = null;
      })
      .addCase(getDeviceSessions.fulfilled, (state, action) => {
        state.deviceSessionsStatus = APIStatus.loaded;
        state.deviceSessions = action.payload;
      })
      .addCase(getDeviceSessions.rejected, (state, action) => {
        state.deviceSessionsStatus = APIStatus.failed;
        state.deviceSessionsError = action.error;
      })
      .addCase(getDeviceFeedsAndRules.pending, (state, action) => {
        state.deviceFeedsStatus = APIStatus.loading;
      })
      .addCase(getDeviceFeedsAndRules.fulfilled, (state, action) => {
        state.deviceFeedsStatus = APIStatus.loaded;
        state.deviceFeeds = action.payload;
      })
      .addCase(getDeviceFeedsAndRules.rejected, (state, action) => {
        state.deviceFeedsStatus = APIStatus.failed;
        state.deviceFeedsError = action.error;
      })
      .addCase(getUpdatedFeedRules.pending, (state, action) => {
        state.updatedFeedRulesStatus = APIStatus.loading;
      })
      .addCase(getUpdatedFeedRules.fulfilled, (state, action) => {
        state.updatedFeedRulesStatus = APIStatus.loaded;
        //TODO
        if (action.meta.arg.action === 'update') {
          state.deviceFeeds.forEach(f => {
            if (f.id === action.meta.arg.id && action.payload) {
              f.feedRules = action.payload.results;
            };
          });
        }
        // state.deviceFeeds = action.payload;
      })
      .addCase(getUpdatedFeedRules.rejected, (state, action) => {
        state.updatedFeedRulesStatus = APIStatus.failed;
        state.updatedFeedRulesError = action.error;
      })
      .addCase(addFeed.pending, (state, action) => {
        state.addFeedStatus = APIStatus.loading;
      })
      .addCase(addFeed.fulfilled, (state, action) => {
        state.addFeedStatus = APIStatus.loaded;
        state.addFeedReceipt = action.payload;
      })
      .addCase(addFeed.rejected, (state, action) => {
        state.addFeedStatus = APIStatus.failed;
        state.addFeedError = action.error;
      })
      .addCase(updateFeed.pending, (state, action) => {
        state.updateFeedStatus = APIStatus.loading;
      })
      .addCase(updateFeed.fulfilled, (state, action) => {
        state.updateFeedStatus = APIStatus.loaded;
        state.updateFeedReceipt = action.payload;
      })
      .addCase(updateFeed.rejected, (state, action) => {
        state.updateFeedStatus = APIStatus.failed;
        state.updateFeedError = action.error;
      })
      .addCase(deleteFeed.pending, (state, action) => {
        state.deleteFeedStatus = APIStatus.loading;
      })
      .addCase(deleteFeed.fulfilled, (state, action) => {
        state.deleteFeedStatus = APIStatus.loaded;
        state.deleteFeedReceipt = 'Deleted Successfully';
      })
      .addCase(deleteFeed.rejected, (state, action) => {
        state.deleteFeedStatus = APIStatus.failed;
        state.deleteFeedError = action.error;
      })
      .addCase(getDeviceFeeds.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(getDeviceFeeds.fulfilled, (state, action) => {
        state.isLoading = APIStatus.loaded;
        state.deviceFeeds = action.payload.data.results;
      });

  }
});

export const { reset, resetAddFeed, resetUpdateFeed, resetDeviceSummary } = feedsSlice.actions;

export default feedsSlice.reducer;