import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { APIStatus } from '../APIStatus';
import { revertAll } from '../globalSlice';

const initialState = {
  processedDataStatus: APIStatus.idle,
  processedData: null,
  processedDataError: null,
  processedDataDetailStatus: APIStatus.idle,
  processedDataDetail: null,
  processedDataDetailError: null,
};

export const getProcessedData = createAsyncThunk('processedDataSlice/getProcessedData', async({ dateSelected }) => {
  const response = await axios.get(`/${localStorage.getItem('organization')}/get-processed-data?file_created_at=${dateSelected}&file_type=unplanned_work_summary`);
  return response.data;
});

export const getProcessedDataDetail = createAsyncThunk('processedDataSlice/getProcessedDataDetail', async({ dataId }) => {
  const response = await axios.get(`/${localStorage.getItem('organization')}/get-processed-data/${dataId}`);
  return response.data;
});

const processedDataSlice = createSlice({
  name: 'processedData',
  initialState,
  reducers: {
    reset(state) {
      state = { ...initialState };
    }
  },
  extraReducers: builder => {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(getProcessedData.pending, (state, action) => {
        state.processedDataStatus = APIStatus.loading;
        state.processedData = null;
      })
      .addCase(getProcessedData.fulfilled, (state, action) => {
        state.processedDataStatus = APIStatus.loaded;
        state.processedData = action.payload;
      })
      .addCase(getProcessedData.rejected, (state, action) => {
        state.processedDataStatus = APIStatus.failed;
        state.processedDataError = action.error;
      })
      .addCase(getProcessedDataDetail.pending, (state, action) => {
        state.processedDataDetailStatus = APIStatus.loading;
        state.processedDataDetail = null;
      })
      .addCase(getProcessedDataDetail.fulfilled, (state, action) => {
        state.processedDataDetailStatus = APIStatus.loaded;
        state.processedDataDetail = action.payload;
      })
      .addCase(getProcessedDataDetail.rejected, (state, action) => {
        state.processedDataDetailStatus = APIStatus.failed;
        state.processedDataDetailError = action.error;
      });
  }
});

export const { reset } = processedDataSlice.actions;

export default processedDataSlice.reducer;