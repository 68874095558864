import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { APIStatus } from '../APIStatus';
import { revertAll } from '../globalSlice';

const initialState = {
  locationsStatus: APIStatus.idle,
  locations: null,
  locationsError: null,
};

export const getLocations = createAsyncThunk('locationsSlice/getLocations', async({ staticLocations, selectedOrg }) => {
  if (selectedOrg === null) {
    selectedOrg = localStorage.getItem('organization');
  }
  const response = await axios.get(`/${selectedOrg}/locations?key=${staticLocations.join(',')}`);

  let locations = response.data.results.features.map((vitem, index) => {
    let item = { ...vitem };
    item.type = 'Polygon';
    let coordinates = item.geometry.coordinates.map(item => {
      return item[0];
    });
    if (item.bbox && item.bbox.length === 4) {
      item.properties.cornersLatLng = [[item.bbox[0], item.bbox[1]], [item.bbox[2], item.bbox[3]]];
    }
    return {
      ...item,
      type: 'Feature',
      geometry: {
        type: item.geometry.type,
        coordinates
      }
    };
  });

  return locations;

});

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    resetLocations(state) {
      state = { ...initialState };
    }
  },
  extraReducers: builder => {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(getLocations.pending, (state, action) => {
        state.locationsStatus = APIStatus.loading;
        state.locations = null;
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.locationsStatus = APIStatus.loaded;
        state.locations = action.payload;
      })
      .addCase(getLocations.rejected, (state, action) => {
        state.locationsStatus = APIStatus.failed;
        state.locationsError = action.error;
      });

  }
});

export const { resetLocations } = locationsSlice.actions;

export default locationsSlice.reducer;