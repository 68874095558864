import { Button, CircularProgress, Grid, Tab, Tabs, TextField, Typography } from '@material-ui/core';
// import logo from "./remoteEye-Logo-Detailed-Grayscale.png";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { loginUser, useUserDispatch } from '../../context/UserContext';

import {usePageTracking} from './../../usePageTracking';
import smalllogo from './SpaceAge-Icon-Grayscale.png';
import useStyles from './styles';

var Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};
var Login = (props) => {
  var classes = useStyles();
  var userDispatch = useUserDispatch();
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [reseterror, ] = useState(null);
  var [resetsuccess, ] = useState(null);
  var [enterpriseError, setenterpriseError] = useState(null);
  var [isLoginForm, ] = useState(true);
  var [, setloginSuccess] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  // var [nameValue, setNameValue] = useState("");
  var [loginValue, setLoginValue] = useState('');
  var [passwordValue, setPasswordValue] = useState('');
  // var [resetemailValue, setresetemailValue] = useState("");
  // var [resetorgValue, setresetorgValue] = useState("");
  var [errorMsg, ] = useState('');
  const [vertical, ] = React.useState('top');
  const [horizontal, ] = React.useState('right');
  // function hideLoginform() { setisloginForm(false) }
  // function showLoginform() { setisloginForm(true) }
  
  usePageTracking();

  useEffect(() => {
    document.title = 'urbanEye - Login';
  });

  return (
    <Grid container className={classes.container}>
      <Grid className={classes.logotypeContainer} item lg={7} md={7} sm={6} xs={12}>
        <img src={smalllogo} alt="logo" className={classes.smalllogotypeImage} />
        <div className={classes.logotypeImage}>
          <Typography variant="h5" weight="bold" style={{ fontSize: 50, fontFamily: 'Rubik', textAlign: 'center', color: '#C0FFB3'}} className={classes.logotype}> urbanEye  </Typography>
          <Typography variant="h5" weight="bold" style={{ fontSize: 50, fontFamily: 'Rubik', textAlign: 'center', color: '#ffffff'}} className={classes.logotype}> GIS Platform  </Typography>
        </div>
        {/* <img src={logo} alt="logo" className={classes.logotypeImage} /> */}
      </Grid>
      <Grid className={classes.formContainer} item lg={5} md={5} sm={6} xs={12}>
        <div className={classes.root}>
          <div>
            <Snackbar anchorOrigin={{ vertical, horizontal }} open={error} autoHideDuration={3000} >
              <Alert severity="error">
                        Login Failed, Invalid Credentials
              </Alert>
            </Snackbar>                
          </div>
          <div>
            <Snackbar anchorOrigin={{ vertical, horizontal }} open={enterpriseError} autoHideDuration={3000}>
              <Alert severity="error">
                       Not a valid Enterprise account
              </Alert>
            </Snackbar>                
          </div>
          <div>
            <Snackbar anchorOrigin={{ vertical, horizontal }} open={resetsuccess} autoHideDuration={3000}>
              <Alert severity="success">
                       Reset Password Email Sent Successfully
              </Alert>
            </Snackbar>                
          </div>
          <div>
            <Snackbar anchorOrigin={{ vertical, horizontal }} open={reseterror} autoHideDuration={3000}>
              <Alert severity="error">
                {errorMsg}
              </Alert>
            </Snackbar>                
          </div>
        </div>
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
            style={{marginBottom: '10%'}}
          >
            {isLoginForm === true ?
              <Tab label="Login" classes={{ root: classes.tab }} /> :
              <Tab label="Password Recovery" classes={{ root: classes.tab }} /> }
          </Tabs>
          {isLoginForm === true && (
            <React.Fragment>
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={e => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Email Address"
                type="email"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={e => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                type="password"
                fullWidth
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    loginUser(
                      userDispatch,
                      loginValue,
                      passwordValue,
                      props.history,
                      setIsLoading,
                      setError,
                      setenterpriseError,
                      setloginSuccess
                    );
                  }
                }}
              />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      loginValue.length === 0 || passwordValue.length === 0
                    }
                    onClick={() =>
                      loginUser(
                        userDispatch,
                        loginValue,
                        passwordValue,
                        props.history,
                        setIsLoading,
                        setError,
                        setenterpriseError,
                        setloginSuccess
                      )
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                      Login
                  </Button>
                )}
                {/* <Button
                  color="primary"
                  size="large"
                  className={classes.forgetButton}
                  // onClick={() =>
                  //   hideLoginform()
                  // }
                >
                  Forgot Password?
                </Button> */}
              </div>
            </React.Fragment>
          )}
        </div>
        <Typography color="primary" className={classes.copyright}>
          &copy; Powered by SpaceAge Labs
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withRouter(Login);
