import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { APIStatus } from '../APIStatus';
import { revertAll } from '../globalSlice';

const initialState = {
  userSettingsStatus: APIStatus.idle,
  userSettings: null,
  userSettingsError: null,
  appconfigNBIOT: null
};

export const getUserSettings = createAsyncThunk('getUserSettings', async(payload) => {
  const response = await axios.get(`/${payload.organization}/users/${payload.email}/settings`);
  return response;
},{
  condition: (arg, { getState, extra }) => {
    const state = getState();

    return state.users.userSettingsStatus !== APIStatus.loading;
  }
});

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    reset(state, action) {
      state = { ...initialState };
    },
    setAppconfigNBIOT(state, action) {
      state.appconfigNBIOT = action.payload;
    },
    resetAppconfigNBIOT(state, action) {
      state.appconfigNBIOT = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(getUserSettings.pending, (state, action) => {
        state.userSettingsStatus = APIStatus.loading;
      })
      .addCase(getUserSettings.fulfilled, (state, action) => {
        state.userSettingsStatus = APIStatus.loaded;
        state.userSettings = action.payload.data;
      })
      .addCase(getUserSettings.rejected, (state, action) => {
        state.userSettingsStatus = APIStatus.failed;
        state.userSettingsError = action.error;
      });
  }
});

export const { reset, setAppconfigNBIOT, resetAppconfigNBIOT } = usersSlice.actions;

export default usersSlice.reducer;