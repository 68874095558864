import axios from 'axios';
import React from 'react';
// import moment from 'moment'
// import {config} from "../config"

const RedirectPageContext = React.createContext();

const rootReducer = (state, action) => {
  switch (action.type) {
  case 'GET_DEVICES':
    return {
      ...state,
      isLoaded: true,
      devices: action.payload,
      error: ''
    };
  case 'GET_DEVICES_ERROR':
    return {
      ...state,
      isLoaded: true,
      devices: null,
      error: action.payload
    };
  case 'GET_ENTERPRISE_ORGS':
    return {
      ...state,
      enterpriseOrgs: action.payload,
      enterpriseOrgsError: ''
    };
  case 'GET_ENTERPRISE_ORGS_ERROR':
    return {
      ...state,
      enterpriseOrgs: null,
      enterpriseOrgsError: action.payload
    };
  case 'GET_ENTERPRISE_META':
    return {
      ...state,
      enterpriseMeta: action.payload,
      enterpriseMetaError: ''
    };
  case 'GET_ENTERPRISE_META_ERROR':
    return {
      ...state,
      enterpriseMeta: null,
      enterpriseMetaError: action.payload
    };
  case 'UPDATE_ENTERPRISE_META':
    return {
      ...state,
      updatedenterpriseMeta: action.payload,
      updatedenterpriseMetaError: ''
    };
  case 'UPDATE_ENTERPRISE_META_ERROR':
    return {
      ...state,
      updatedenterpriseMeta: null,
      updatedenterpriseMetaError: action.payload
    };
  case 'GET_WORK_CYCLES_LIST':
    return {
      ...state,
      workCyclesList: action.payload,
      workCyclesListError: ''
    };
  case 'GET_WORK_CYCLES_LIST_ERROR':
    return {
      ...state,
      workCyclesList: null,
      workCyclesListError: action.payload
    };

  default:
    return state;
  }
};

const RedirectPageProvider = ({ children }) => {
  const [data, setData] = React.useReducer(rootReducer, {
    isLoaded: false,
    devices: null,
    gpsData: null,
    summaryData: null,
    error: ''
  });
  return (
    <RedirectPageContext.Provider value={{ data, setData }}>
      {children}
    </RedirectPageContext.Provider>
  );
};

const useRedirectPageState = () => {
  const context = React.useContext(RedirectPageContext);
  return context;
};

export function getWorkCycles(dispatch) {

  axios.get(`/${localStorage.getItem('organization')}/work-cycles`).then(res => {
    dispatch({ type: 'GET_WORK_CYCLES_LIST', payload: res.data.results });
  }).catch(err => {
    dispatch({ type: 'GET_WORK_CYCLES_LIST_ERROR', payload: err });
  });
}

export function getEntepriseOrgs(dispatch) {
  let enterpriseOrg = localStorage.getItem('enterpriseOrganization');
  return axios.get(`/${enterpriseOrg}/enterprise-org`).then(res => {
    dispatch({ type: 'GET_ENTERPRISE_ORGS', payload: res.data });
  }).catch(err => {
    dispatch({ type: 'GET_ENTERPRISE_ORGS_ERROR', payload: err });
  });
}

export function getEnterpriseMeta(dispatch) {
  let enterpriseOrg = localStorage.getItem('enterpriseOrganization');
  return axios.get(`/organizations/${enterpriseOrg}`).then(res => {
    dispatch({ type: 'GET_ENTERPRISE_META', payload: res.data });
  }).catch(err => {
    dispatch({ type: 'GET_ENTERPRISE_META_ERROR', payload: err });
  });
}

export function updateEnterpriseMeta(dispatch, updateBody) {
  let enterpriseOrg = localStorage.getItem('enterpriseOrganization');
  return axios.patch(`/organizations/${enterpriseOrg}`, updateBody).then(res => {
    dispatch({ type: 'UPDATE_ENTERPRISE_META', payload: res.data });
  }).catch(err => {
    dispatch({ type: 'UPDATE_ENTERPRISE_META_ERROR', payload: err });
  });
}

export { RedirectPageProvider, RedirectPageContext, useRedirectPageState };