import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    fontFamily: 'Karla,sans-serif',
    marginBottom: '2%',
    '&:focus': {
      backgroundColor: '#e3f7f7',
    },
  },
  linkActive: {
    //backgroundColor: theme.palette.background.light,
    background: '#e3f7f7',
    borderRadius: '8px',
    width: '90%'
  },
  linkNested: {
    paddingLeft: 0,
    '&:hover, &:focus': {
      backgroundColor: '#e3f7f7',
    },
  },
  linkIcon: {
    marginRight: theme.spacing(1.2),
    color: `${theme.palette.text.secondary  }99`,
    transition: theme.transitions.create('color'),
    minWidth: 10,
    width: 24,
    display: 'flex',
    justifyContent: 'left',
  },
  linkIconActive: {
    color: theme.palette.primary.main,
  },
  linkText: {
    padding: 0,
    color: '#698D8D', //theme.palette.text.secondary + "CC",
    transition: theme.transitions.create(['opacity', 'color']),
    fontSize: 14,
    fontWeight: 'bold'
  },
  linkTextActive: {
    color: '#2C7873',
    backgroundColor: '#e3f7f7',
    fontWeight: 'bold'
  },
  linkTextHidden: {
    opacity: 0,
  },
  nestedList: {
    paddingLeft: theme.spacing(2) + 30,
  },
  sectionTitle: {
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: 1,
    backgroundColor: '#D8D8D880',
  },
}));
