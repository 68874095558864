import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';

import { APIStatus } from '../APIStatus';

const initialState = {
  locationsStatus: APIStatus.idle,
  locations: null,
  locationsError: null,
  devicesStatus: APIStatus.idle,
  devices: null,
  devicesError: null,
  addLocationStatus: APIStatus.idle,
  addLocationReceipt: null,
  addLocationError: null,
  editLocationStatus: APIStatus.idle,
  editLocationReceipt: null,
  editLocationError: null,
  deviceDataStatus: APIStatus.idle,
  deviceData: null,
  deviceDataError: null,
  deviceLocationsStatus: APIStatus.idle,
  deviceLocations: null,
  deviceLocationsError: null,
  feedDataStatus: APIStatus.idle,
  feedData: null,
  feedDataError: null,
  previousDataStatus: APIStatus.idle,
  previousData: null,
  previousDataError: null,
  previousDeviceDataStatus: APIStatus.idle,
  previousDeviceData: null,
  previousDeviceDataError: null,
  previousStreamDataStatus: APIStatus.idle,
  previousStreamData: null,
  previousStreamDataError: null,
};

export const getLocations = createAsyncThunk('locationManagementSlice/getLocations', async({ offset, search, selectedOrg }) => {
  let offsetValue = offset * 15;
  let apiEndPoint = '';

  if (search == null) {
    apiEndPoint = `/${selectedOrg}/locations?limit=15&offset=${offsetValue}`;
  } else {
    apiEndPoint = `/${selectedOrg}/locations?key=${search}`;
  }

  const response = await axios.get(apiEndPoint);

  let locations = {
    length: response.data.count,
    data: response.data.results.features.map((item, index) => {
      if (item.bbox && item.bbox.length === 4) {
        item.properties.cornersLatLng = [[item.bbox[0], item.bbox[1]], [item.bbox[2], item.bbox[3]]];
      }
      return {
        ...item,
      };
    })
  };
  return locations;
});

export const getDevices = createAsyncThunk('locationManagementSlice/getDevices', async({ organization, isEnterpriseUser }) => {
  const response = await axios.get(`/${organization}/devices?limit=250`);
  let orgdevicesArr = [];
  if (response.data && response.data.results.length > 0) {
    if (isEnterpriseUser === 'true') {
      orgdevicesArr = response.data.results;
    } else {
      orgdevicesArr = response.data.results.filter(r => r.organization_key === organization);
    }
  }
  return { results: orgdevicesArr };
});

export const addLocation = createAsyncThunk('locationManagementSlice/addLocation', async({ inputData, selectedOrg }) => {
  const response = await axios.post(`/${selectedOrg}/locations`, inputData);
  return response.data;
});

export const editLocation = createAsyncThunk('locationManagementSlice/editLocation', async({ locationKey, inputData, selectedOrg }) => {
  const response = await axios.patch(`/${selectedOrg}/locations/${locationKey}`, inputData);
  return response.data;
});

export const getDeviceData = createAsyncThunk('locationManagementSlice/getDeviceData', async({ deviceId, selectedDate, selectedOrg, deviceOrg }) => {

  var startDate = moment(selectedDate).format('YYYY-MM-DD');
  var endDate = moment(selectedDate).format('YYYY-MM-DD');
  var startTime = `${startDate} 00:00`;
  var endTime = `${endDate} 23:59`;

  var utcstartDate = moment(startTime).utc().format('YYYY-MM-DD HH:mm:ss');
  var utcendDate = moment(endTime).utc().format('YYYY-MM-DD HH:mm:ss');

  const response = await axios.get(`/${deviceOrg}/devices/${deviceId}/device-data?completed_at__range=${utcstartDate}, ${utcendDate}`);
  return response.data;
});

export const getFeedData = createAsyncThunk('locationManagementSlice/getFeedData', async({ deviceId, selectedOrg, deviceOrg }) => {
  var startDate = moment().format('YYYY-MM-DD');
  var endDate = moment().format('YYYY-MM-DD');
  var startTime = `${startDate} 00:00`;
  var endTime = `${endDate} 23:59`;
  let mapData = [];
  var utcstartDate = moment(startTime).utc().format('YYYY-MM-DD HH:mm:ss');
  var utcendDate = moment(endTime).utc().format('YYYY-MM-DD HH:mm:ss');

  let offsetVal = 0;

  const response = await axios.get(`/${deviceOrg}/devices/${deviceId}/stream-data-full?completed_at__range=${utcstartDate},${utcendDate}&limit=3000&offset=${offsetVal}`);

  let totalCount = response.data.count;
  let noofLoops = (totalCount / 3000);
  let streamdatareq = [];
  let streamdatares = [];
  streamdatares.push(response.data.results);
  if (noofLoops > 1) {
    let loopInt = Number(noofLoops.toFixed(0));
    //let loopDecimal = Number(noofLoops.toFixed(1))
    let forloops = loopInt;
    // if(loopDecimal > loopInt){
    //     forloops = (Number(loopInt) + 1)
    // }
    for (let j = 0; j < forloops; j++) {
      offsetVal = offsetVal + 3000;
      streamdatareq.push(axios.get(`/${deviceOrg}/devices/${deviceId}/stream-data-full?completed_at__range=${utcstartDate},${utcendDate}&limit=3000&offset=${offsetVal}`));
    }
    const streamResp = await axios.all(streamdatareq);

    streamResp.forEach(s => {
      streamdatares.push(s.data.results);
    });
    if (streamdatares.length > 1) {
      var allDataArr = [].concat.apply([], streamdatares);
      mapData = allDataArr;
    }

  }
  else {
    mapData = response.data.results;
    // let isDataFound = mapData && mapData.length > 0;
  }

  return mapData;

});

export const getDeviceLocations = createAsyncThunk('locationManagementSlice/getDeviceLocations', async({ search, selectedOrg }) => {
  const response = await axios.get(`/${selectedOrg}/locations?key=${search}`);

  let locations = response.data.results.features.map((item, index) => {
    // item.geometry.type = 'Polygon'
    // item.type = 'Polygon'
    // // item.properties.zone = zoneArr[r]
    let { coordinates } = item.geometry;
    // .map(item => {
    //     return item[0];
    // });
    if (item.bbox && item.bbox.length === 4) {
      item.properties.cornersLatLng = [[item.bbox[0], item.bbox[1]], [item.bbox[2], item.bbox[3]]];
    }
    return {
      ...item,
      type: 'Feature',
      // properties: item,
      geometry: {
        type: item.geometry.type, //'Polygon',
        coordinates
      }
    };
  });

  return locations;
});

export const getPreviousData = createAsyncThunk('locationManagementSlice/getPreviousData', async({ deviceUUID, device_id, selectedDate, selectedOrg, deviceOrg }) => {
  const response = await axios.get(`/${deviceOrg}/location-processed-data?file_created_at=${selectedDate}&device__device_id=${device_id}`);

  let tData = response.data.results.filter(t => {
    // let dId = ''
    if (t.file_type === 'stream_data' || t.file_type === 'device_data' || t.file_type === 'device_summary' || t.file_type === 'device_session_summary') {
      if (t.device) {
        // dId = t.device;
        if (t.device === deviceUUID) return true;
      } else {
        // dId = t.file_name.split('_')[0]
        if (t.file_name.split('_')[0] === device_id) return true;
      }
    }
    // return deviceId === t.device && t.file_type === 'trim_data'
    return false;
  });

  return tData;
});

export const getPreviousDeviceData = createAsyncThunk('locationManagementSlice/getPreviousDeviceData', async({ itemId, selectedOrg }) => {
  const response = await axios.get(`/${selectedOrg}/location-processed-data/${itemId}`);
  return response.data;
});

export const getPreviousStreamData = createAsyncThunk('locationManagementSlice/getPreviousStreamData', async({ itemId, selectedOrg }) => {
  const response = await axios.get(`/${selectedOrg}/location-processed-data/${itemId}`);
  return response.data;
});

// resetPreviousStreamData(state, action) {
//   state.previousStreamDataStatus =  APIStatus.idle;
//   state.previousStreamData = null;
//   state.previousStreamDataError = null;
// },

// resetPreviousDeviceData(state, action) {
//   state.previousDeviceDataStatus =  APIStatus.idle;
//   state.previousDeviceData = null;
//   state.previousDeviceDataError = null;
// },

// resetPreviousData(state, action) {
//   state.previousDataStatus =  APIStatus.idle;
//   state.previousData = null;
//   state.previousDataError = null;
// },

// resetFeedData(state, action) {
//   state.feedDataStatus =  APIStatus.idle;
//   state.feedData = null;
//   state.feedDataError = null;
// },

// resetDeviceLocations(state, action) {
//   state.deviceLocationsStatus =  APIStatus.idle;
//   state.deviceLocations = null;
//   state.deviceLocationsError = null;
// },

// resetDeviceData(state, action) {
//   state.deviceDataStatus =  APIStatus.idle;
//   state.deviceData = null;
//   state.deviceDataError = null;
// },

// resetEditLocation(state, action) {
//   state.editLocationStatus =  APIStatus.idle;
//   state.editLocationReceipt = null;
//   state.editLocationError = null;
// },

// resetAddLocation(state, action) {
//   state.addLocationStatus = APIStatus.idle;
//   state.addLocationReceipt = null;
//   state.addLocationError = null;
// },

// resetDevices(state, action) {
//   state.devicesStatus =  APIStatus.idle;
//   state.devices = null;
//   state.devicesError = null;
// },
// resetLocations(state, action) {
//   state.locationsStatus =  APIStatus.idle;
//   state.locations = null;
//   state.locationsError = null;
// },

const locationManagementSlice = createSlice({
  name: 'locationManagement',
  initialState,
  reducers: {
    reset(state) {
      state.locationsStatus = APIStatus.idle;
      state.locations = null;
      state.locationsError = null;
      state.devicesStatus = APIStatus.idle;
      state.devices = null;
      state.devicesError = null;
      state.addLocationStatus = APIStatus.idle;
      state.addLocationReceipt = null;
      state.addLocationError = null;
      state.editLocationStatus =  APIStatus.idle;
      state.editLocationReceipt = null;
      state.editLocationError = null;
      state.deviceLocationsStatus = APIStatus.idle;
      state.deviceLocations = null;
      state.deviceLocationsError = null;
      state.feedDataStatus = APIStatus.idle;
      state.feedData = null;
      state.feedDataError = null;
      state.previousDataStatus = APIStatus.idle;
      state.previousData = null;
      state.previousDataError = null;
      state.previousStreamDataStatus = APIStatus.idle;
      state.previousStreamData = null;
      state.previousStreamDataError = null;
      state.previousDeviceDataStatus = APIStatus.idle;
      state.previousDeviceData = null;
      state.previousDeviceDataError = null;
    },
    resetDeviceLocations(state, action) {
      state.deviceLocationsStatus = APIStatus.idle;
      state.deviceLocations = null;
      state.deviceLocationsError = null;
    },
    clearDetailData(state, action) {
      state.feedDataStatus = APIStatus.idle;
      state.feedData = null;
      state.feedDataError = null;
      state.previousDataStatus = APIStatus.idle;
      state.previousData = null;
      state.previousDataError = null;
      state.previousStreamDataStatus = APIStatus.idle;
      state.previousStreamData = null;
      state.previousStreamDataError = null;
      state.previousDeviceDataStatus = APIStatus.idle;
      state.previousDeviceData = null;
      state.previousDeviceDataError = null;
      state.deviceLocationsStatus = APIStatus.idle;
      state.deviceLocations = null;
      state.deviceLocationsError = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getLocations.pending, (state, action) => {
        state.locationsStatus = APIStatus.loading;
        state.locations = null;
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.locationsStatus = APIStatus.loaded;
        state.locations = action.payload;
      })
      .addCase(getLocations.rejected, (state, action) => {
        state.locationsStatus = APIStatus.failed;
        state.locationsError = action.error;
      })
      .addCase(getDevices.pending, (state, action) => {
        state.devicesStatus = APIStatus.loading;
        state.devices = null;
      })
      .addCase(getDevices.fulfilled, (state, action) => {
        state.devicesStatus = APIStatus.loaded;
        state.devices = action.payload;
      })
      .addCase(getDevices.rejected, (state, action) => {
        state.devicesStatus = APIStatus.failed;
        state.devicesError = action.error;
      })
      .addCase(addLocation.pending, (state, action) => {
        state.addLocationStatus = APIStatus.loading;
        state.addLocationReceipt = null;
      })
      .addCase(addLocation.fulfilled, (state, action) => {
        state.addLocationStatus = APIStatus.loaded;
        state.addLocationReceipt = action.payload;
      })
      .addCase(addLocation.rejected, (state, action) => {
        state.addLocationStatus = APIStatus.failed;
        state.addLocationError = action.error;
      })
      .addCase(editLocation.pending, (state, action) => {
        state.editLocationStatus = APIStatus.loading;
        state.editLocationReceipt = null;
      })
      .addCase(editLocation.fulfilled, (state, action) => {
        state.editLocationStatus = APIStatus.loaded;
        state.editLocationReceipt = action.payload;
      })
      .addCase(editLocation.rejected, (state, action) => {
        state.editLocationStatus = APIStatus.failed;
        state.editLocationError = action.error;
      })
      .addCase(getDeviceData.pending, (state, action) => {
        state.deviceDataStatus = APIStatus.loading;
        state.deviceData = null;
      })
      .addCase(getDeviceData.fulfilled, (state, action) => {
        state.deviceDataStatus = APIStatus.loaded;
        state.deviceData = action.payload;
      })
      .addCase(getDeviceData.rejected, (state, action) => {
        state.deviceDataStatus = APIStatus.failed;
        state.deviceDataError = action.error;
      })
      .addCase(getDeviceLocations.pending, (state, action) => {
        state.deviceLocationsStatus = APIStatus.loading;
        state.deviceLocations = null;
      })
      .addCase(getDeviceLocations.fulfilled, (state, action) => {
        state.deviceLocationsStatus = APIStatus.loaded;
        state.deviceLocations = action.payload;
      })
      .addCase(getDeviceLocations.rejected, (state, action) => {
        state.deviceLocationsStatus = APIStatus.failed;
        state.deviceLocationsError = action.error;
      })
      .addCase(getFeedData.pending, (state, action) => {
        state.feedDataStatus = APIStatus.loading;
        state.feedData = null;
      })
      .addCase(getFeedData.fulfilled, (state, action) => {
        state.feedDataStatus = APIStatus.loaded;
        state.feedData = action.payload;
      })
      .addCase(getFeedData.rejected, (state, action) => {
        state.feedDataStatus = APIStatus.failed;
        state.feedDataError = action.error;
      })
      .addCase(getPreviousData.pending, (state, action) => {
        state.previousDataStatus = APIStatus.loading;
        state.previousData = null;
      })
      .addCase(getPreviousData.fulfilled, (state, action) => {
        state.previousDataStatus = APIStatus.loaded;
        state.previousData = action.payload;
      })
      .addCase(getPreviousData.rejected, (state, action) => {
        state.previousDataStatus = APIStatus.failed;
        state.previousDataError = action.error;
      })
      .addCase(getPreviousDeviceData.pending, (state, action) => {
        state.previousDeviceDataStatus = APIStatus.loading;
        state.previousDeviceData = null;
      })
      .addCase(getPreviousDeviceData.fulfilled, (state, action) => {
        state.previousDeviceDataStatus = APIStatus.loaded;
        state.previousDeviceData = action.payload;
      })
      .addCase(getPreviousDeviceData.rejected, (state, action) => {
        state.previousDeviceDataStatus = APIStatus.failed;
        state.previousDeviceDataError = action.error;
      })
      .addCase(getPreviousStreamData.pending, (state, action) => {
        state.previousStreamDataStatus = APIStatus.loading;
        state.previousStreamData = null;
      })
      .addCase(getPreviousStreamData.fulfilled, (state, action) => {
        state.previousStreamDataStatus = APIStatus.loaded;
        state.previousStreamData = action.payload;
      })
      .addCase(getPreviousStreamData.rejected, (state, action) => {
        state.previousStreamDataStatus = APIStatus.failed;
        state.previousStreamDataError = action.error;
      });
  }
});

export const { reset, resetDeviceLocations, clearDetailData } = locationManagementSlice.actions;

export default locationManagementSlice.reducer;