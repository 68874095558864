import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { APIStatus } from '../APIStatus';
import { revertAll } from '../globalSlice';

const initialState = {
  devicesStatus: APIStatus.idle,
  devices: null,
  devicesError: null,
  gpsDataStatus: APIStatus.idle,
  gpsData: null,
  allDevices: null,
  gpsDataError: null,
  deviceDetailsStatus: APIStatus.idle,
  deviceDetails: null,
  deviceDetailsError: null,
  addDeviceStatus: APIStatus.idle,
  addDeviceReceipt: null,
  addDeviceError: null,
  updateDeviceStatus: APIStatus.idle,
  updateDeviceReceipt: null,
  updateDeviceError: null,
  deleteDeviceImageStatus: APIStatus.idle,
  deleteDeviceImageReceipt: null,
  deleteDeviceImageError: null,
  updateDeviceConfigStatus: APIStatus.idle,
  updateDeviceConfigReceipt: null,
  updateDeviceConfigError: null,
  csvFeedStatus: APIStatus.idle,
  csvFeed: null,
  csvFeedError: null,
  csvDetailStatus: APIStatus.idle,
  csvDetail: null,
  csvDetailError: null,
  processedDataStatus: APIStatus.idle,
  processedData: null,
  processedDataError: null,
  processedDataDetailStatus: APIStatus.idle,
  processedDataDetail: null,
  processedDataDetailError: null,
  deviceCloneStatus: APIStatus.idle,
  deviceCloneReceipt: null,
  deviceCloneError: null,
};

export const getDevices = createAsyncThunk('devicesSlice/getDevices', async({ organization, isEnterprise }) => {
  let orgsList = [];
  if (isEnterprise === true && !window.ReactNativeWebView) {
    let reqArr = [];
    let devicesArr = [];

    const response = await axios.get(`/${organization}/enterprise-org`);

    orgsList.push(organization);
    reqArr.push(axios.get(`/${organization}/devices`));
    if (response.data && response.data.results && response.data.results.length > 0) {
      response.data.results.forEach(r => {
        orgsList.push(r.key);
        reqArr.push(axios.get(`/${r.key}/devices`));
      });
    }
    const responses = await axios.all(reqArr);

    responses.forEach((device, i) => {
      if (device.data.results && device.data.results.length > 0) {
        devicesArr = devicesArr.concat(device.data.results);
      }
    });
    devicesArr = devicesArr && devicesArr.filter((v, i, a) => a.findIndex(t => (t.device_id === v.device_id)) === i);
    return { results: devicesArr, orgsList };

  } 
  const response = await axios.get(`/${organization ? organization : localStorage.getItem('organization')}/devices`);
  if (organization) {
    let orgdevicesArr = [];
    if (response.data && response.data.results.length > 0) {
      orgdevicesArr = response.data.results.filter(r => r.organization_key === organization);
    }
    return { results: orgdevicesArr, orgsList };
  }
  return response.data;
  
});

export const getGpsData = createAsyncThunk('devicesSlice/getGpsData', async({ devicesList, isEnterprise, orgsList }) => {
  let metaArr = [];
  let gpsData;
  let allDevices;
  if (devicesList) {
    if (isEnterprise === true && !window.ReactNativeWebView) {
      orgsList && orgsList.forEach(o => {
        metaArr.push(axios.get(`/${o}/devices-metas`));
      });
    }
    else {
      metaArr.push(axios.get(`/${localStorage.getItem('organization')}/devices-metas`));
    }
    const metaResp = await axios.all(metaArr);

    if (metaResp) {
      // metaResp && metaResp.forEach(m => {
      //   let devicesMeta = m.data.results;
      gpsData = devicesList.map(dv => {
        let d = { ...dv };
        let mdata = null;
        metaResp && metaResp.forEach(m => {
          let devicesMeta = m.data.results;
          let mdataFil = devicesMeta.filter(m => m.device_id === d.device_id);
          if(mdataFil.length > 0) {
            mdata = mdataFil;
          }
        });
          
        if (mdata && mdata.length > 0) {
          d.meta = mdata[0];
          if (mdata[0].last_gps && mdata[0].last_gps.coordinates && mdata[0].last_gps.coordinates.length === 2) {
            d.devicelocation = { lat: mdata[0].last_gps.coordinates[1], long: mdata[0].last_gps.coordinates[0] };
            d.is_active = mdata[0].is_active;
            d.last_seen = mdata[0].last_seen_gps;
            d.calibrated_height = mdata[0].calibrated_height;
          }
          else {
            d.devicelocation = { lat: d.latitude, long: d.longitude };
            d.calibrated_height = '';
          }
        }
        return d;
      });
      // });

      allDevices = gpsData;
      gpsData = gpsData.filter(d => d.devicelocation);
    }
    return {
      gpsData,
      allDevices
    };
  }
});

export const getDeviceDetails = createAsyncThunk('devicesSlice/getDeviceDetails', async({ deviceId, selectedOrg }) => {
  const response = await axios.get(`/${selectedOrg}/devices/${deviceId}`);
  return response.data;
});

export const addDevice = createAsyncThunk('addDevice', async({ organization, data }) => {
  const response = await axios.post(`/${organization}/devices`, data);
  return response.data;
});

export const updateDevice = createAsyncThunk('updateDevice', async({ organization, id, data }) => {
  const response = await axios.patch(`/${organization}/devices/${id}`, data);
  return response.data;
});

export const deleteDeviceImage = createAsyncThunk('deleteDeviceImage', async({ organization, deviceId, id }) => {
  const response = await axios.delete(`/${organization}/devices/${deviceId}/delete-image/${id}`);
  return response;
});

export const updateDeviceConfig = createAsyncThunk('updateDeviceConfig', async({ organization, id, data }) => {
  const response = await axios.patch(`/${organization}/devices/${id}`, data);
  return response.data;
});

export const getCSVFeed = createAsyncThunk('getCSVFeed', async({ organization, data }) => {
  const response = await axios.post(`/${organization}/csvdumps`, data);
  return response.data;
});

export const getCSVDetail = createAsyncThunk('getCSVDetail', async({ organization, id }) => {
  const response = await axios.get(`/${organization}/csvdump-details/${id}`);
  return response.data;
});

export const getProcessedData = createAsyncThunk('getProcessedData', async({ organization, id, type }) => {
  const response = await axios.get(`/${organization}/get-processed-data?device=${id}&file_type=${type}`);
  return response.data;
});

export const getProcessedDataDetail = createAsyncThunk('getProcessedDataDetail', async({ organization, id }) => {
  const response = await axios.get(`/${organization}/get-processed-data/${id}`);
  return response.data;
});

export const updateDeviceClone = createAsyncThunk('updateDeviceClone', async({ organization, id, data }) => {
  const response = await axios.post(`/${organization}/devices/${id}/clone`, data);
  return response.data;
});

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    reset(state) {
      state = { ...initialState };
    },
    resetDevices(state) {
      state.devicesStatus = APIStatus.idle;
      state.devices = null;
      state.devicesError = null;
    },
    resetUpdateDevice(state, action) {
      state.updateDeviceStatus = APIStatus.idle;
      state.updateDeviceReceipt = null;
      state.updateDeviceError = null;
    },
    resetDeleteDeviceImage(state, action) {
      state.deleteDeviceImageStatus = APIStatus.idle;
      state.deleteDeviceImageReceipt = null;
      state.deleteDeviceImageError = null;
    },
    resetUpdateDeviceConfig(state, action) {
      state.updateDeviceConfigStatus = APIStatus.idle;
      state.updateDeviceConfigReceipt = null;
      state.updateDeviceConfigError = null;
    },
    resetCsvDetail(state, action) {
      state.csvFeedStatus = APIStatus.idle;
      state.csvFeed = null;
      state.csvFeedError = null;
      state.csvDetailStatus = APIStatus.idle;
      state.csvDetail = null;
      state.csvDetailError = null;
    },
    resetProcessedDataDetail(state, action) {
      state.processedDataStatus = APIStatus.idle;
      state.processedData = null;
      state.processedDataError = null;
      state.processedDataDetailStatus = APIStatus.idle;
      state.processedDataDetail = null;
      state.processedDataDetailError = null;
    },
    resetDeviceClone(state, action) {
      state.deviceCloneStatus = APIStatus.idle;
      state.deviceCloneReceipt = null;
      state.deviceCloneError = null;
    },
    resetAddDevice(state, action) {
      state.addDeviceStatus = APIStatus.idle;
      state.addDeviceReceipt = null;
      state.addDeviceError = null;
    },
    resetDeviceDetails(state) {
      state.deviceDetailsStatus = APIStatus.idle;
      state.deviceDetails = null;
      state.deviceDetailsError = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(getDevices.pending, (state) => {
        state.devicesStatus = APIStatus.loading;
        state.devices = null;
      })
      .addCase(getDevices.fulfilled, (state, action) => {
        state.devicesStatus = APIStatus.loaded;
        state.devices = action.payload;
      })
      .addCase(getDevices.rejected, (state, action) => {
        state.devicesStatus = APIStatus.failed;
        state.devicesError = action.error;
      })
      .addCase(getGpsData.pending, (state, action) => {
        state.gpsDataStatus = APIStatus.loading;
        state.gpsData = null;
      })
      .addCase(getGpsData.fulfilled, (state, action) => {
        state.gpsDataStatus = APIStatus.loaded;
        state.gpsData = action.payload.gpsData;
        state.allDevices = action.payload.allDevices;
      })
      .addCase(getGpsData.rejected, (state, action) => {
        state.gpsDataStatus = APIStatus.failed;
        state.gpsDataError = action.error;
      })
      .addCase(getDeviceDetails.pending, (state, action) => {
        state.deviceDetailsStatus = APIStatus.loading;
        state.deviceDetails = null;
      })
      .addCase(getDeviceDetails.fulfilled, (state, action) => {
        state.deviceDetailsStatus = APIStatus.loaded;
        state.deviceDetails = action.payload;
        if (action.meta.arg.customAction === 'update') {
          state.devices = state.devices.map(d => {
            if (d.id === action.payload.id) {
              return {
                ...action.payload
              };
            }
            return d;
          });
        }
      })
      .addCase(getDeviceDetails.rejected, (state, action) => {
        state.deviceDetailsStatus = APIStatus.failed;
        state.deviceDetailsError = action.error;
      })
      .addCase(updateDevice.pending, (state, action) => {
        state.updateDeviceStatus = APIStatus.loading;
      })
      .addCase(updateDevice.fulfilled, (state, action) => {
        state.updateDeviceStatus = APIStatus.loaded;
        state.updateDeviceReceipt = action.payload;
      })
      .addCase(updateDevice.rejected, (state, action) => {
        state.updateDeviceStatus = APIStatus.failed;
        state.updateDeviceError = action.error;
      })
      .addCase(deleteDeviceImage.pending, (state, action) => {
        state.deleteDeviceImageStatus = APIStatus.loading;
      })
      .addCase(deleteDeviceImage.fulfilled, (state, action) => {
        state.deleteDeviceImageStatus = APIStatus.loaded;
        state.deleteDeviceImageReceipt = 'Deleted Successfully';
      })
      .addCase(deleteDeviceImage.rejected, (state, action) => {
        state.deleteDeviceImageStatus = APIStatus.failed;
        state.deleteDeviceImageError = action.error;
      })
      .addCase(updateDeviceConfig.pending, (state, action) => {
        state.updateDeviceConfigStatus = APIStatus.loading;
      })
      .addCase(updateDeviceConfig.fulfilled, (state, action) => {
        state.updateDeviceConfigStatus = APIStatus.loaded;
        state.updateDeviceConfigReceipt = action.payload;
      })
      .addCase(updateDeviceConfig.rejected, (state, action) => {
        state.updateDeviceConfigStatus = APIStatus.failed;
        state.updateDeviceConfigError = action.error;
      })
      .addCase(getCSVFeed.pending, (state, action) => {
        state.csvFeedStatus = APIStatus.loading;
      })
      .addCase(getCSVFeed.fulfilled, (state, action) => {
        state.csvFeedStatus = APIStatus.loaded;
        state.csvFeed = action.payload;
      })
      .addCase(getCSVFeed.rejected, (state, action) => {
        state.csvFeedStatus = APIStatus.failed;
        state.csvFeedError = action.error;
      })
      .addCase(getCSVDetail.pending, (state, action) => {
        state.csvDetailStatus = APIStatus.loading;
      })
      .addCase(getCSVDetail.fulfilled, (state, action) => {
        state.csvDetailStatus = APIStatus.loaded;
        state.csvDetail = action.payload;
      })
      .addCase(getCSVDetail.rejected, (state, action) => {
        state.csvDetailStatus = APIStatus.failed;
        state.csvDetailError = action.error;
      })
      .addCase(getProcessedData.pending, (state, action) => {
        state.processedDataStatus = APIStatus.loading;
      })
      .addCase(getProcessedData.fulfilled, (state, action) => {
        state.processedDataStatus = APIStatus.loaded;
        state.processedData = action.payload;
      })
      .addCase(getProcessedData.rejected, (state, action) => {
        state.processedDataStatus = APIStatus.failed;
        state.processedDataError = action.error;
      })
      .addCase(getProcessedDataDetail.pending, (state, action) => {
        state.processedDataDetailStatus = APIStatus.loading;
      })
      .addCase(getProcessedDataDetail.fulfilled, (state, action) => {
        state.processedDataDetailStatus = APIStatus.loaded;
        state.processedDataDetail = action.payload;
      })
      .addCase(getProcessedDataDetail.rejected, (state, action) => {
        state.processedDataDetailStatus = APIStatus.failed;
        state.processedDataDetailError = action.error;
      })
      .addCase(updateDeviceClone.pending, (state, action) => {
        state.deviceCloneStatus = APIStatus.loading;
      })
      .addCase(updateDeviceClone.fulfilled, (state, action) => {
        state.deviceCloneStatus = APIStatus.loaded;
        state.deviceCloneReceipt = action.payload;
      })
      .addCase(updateDeviceClone.rejected, (state, action) => {
        state.deviceCloneStatus = APIStatus.failed;
        state.deviceCloneError = action.error;
      })
      .addCase(addDevice.pending, (state, action) => {
        state.addDeviceStatus = APIStatus.idle;
        state.addDeviceReceipt = null;
        state.addDeviceError = null;
      })
      .addCase(addDevice.fulfilled, (state, action) => {
        state.addDeviceStatus = APIStatus.loaded;
        state.addDeviceReceipt = action.payload;
      })
      .addCase(addDevice.rejected, (state, action) => {
        state.addDeviceStatus = APIStatus.failed;
        state.addDeviceError = action.error;
      });
  }
});

export const { reset, resetAddDevice, resetUpdateDevice, resetDeleteDeviceImage, resetUpdateDeviceConfig, resetCsvDetail, resetProcessedDataDetail, resetDeviceClone, resetDeviceDetails } = devicesSlice.actions;

export default devicesSlice.reducer;