import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { APIStatus } from '../APIStatus';
import { revertAll } from '../globalSlice';

const initialState = {
  deviceGroupsStatus: APIStatus.idle,
  deviceGroups: null,
  deviceGroupsError: null,
  addDeviceGroupStatus: APIStatus.idle,
  addDeviceGroupReceipt: null,
  addDeviceGroupError: null,
  updateDeviceGroupStatus: APIStatus.idle,
  updateDeviceGroupReceipt: null,
  updateDeviceGroupError: null,
  deleteDeviceGroupStatus: APIStatus.idle,
  deleteDeviceGroupReceipt: null,
  deleteDeviceGroupError: null
};

export const getDeviceGroups = createAsyncThunk('getDeviceGroups', async(payload) => {
  const response = await axios.get(`/${localStorage.getItem('organization')}/devices-groups`);
  return response;
});

export const addDeviceGroup = createAsyncThunk('addDeviceGroup', async(payload) => {
  const response = await axios.post(`/${localStorage.getItem('organization')}/devices-groups`, payload.data);
  return response;
});

export const updateDeviceGroup = createAsyncThunk('updateDeviceGroup', async(payload) => {
  const response = await axios.patch(`/${localStorage.getItem('organization')}/devices-groups/${payload.data.id}`, payload.data);
  return response;
});

export const deleteDeviceGroup = createAsyncThunk('deleteDeviceGroup', async(payload) => {
  const response = await axios.delete(`/${localStorage.getItem('organization')}/devices-groups/${payload.id}`);
  return response;
});

const deviceGroupsSlice = createSlice({
  name: 'deviceGroups',
  initialState,
  reducers: {
    reset(state, action) {
      state = {...initialState};
    },
    resetUpdateDeviceGroup(state, action) {
      state.updateDeviceGroupStatus =  APIStatus.idle;
      state.updateDeviceGroupReceipt = null;
      state.updateDeviceGroupError = null;
    },
    resetAddDeviceGroup(state, action) {
      state.addDeviceGroupStatus =  APIStatus.idle;
      state.addDeviceGroupReceipt = null;
      state.addDeviceGroupError = null;
    },
    resetDeleteDeviceGroup(state, action) {
      state.deleteDeviceGroupStatus =  APIStatus.idle;
      state.deleteDeviceGroupReceipt = null;
      state.deleteDeviceGroupError = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(getDeviceGroups.pending, (state, action) => {
        state.deviceGroupsStatus = APIStatus.loading;
      })
      .addCase(getDeviceGroups.fulfilled, (state, action) => {
        state.deviceGroupsStatus = APIStatus.loaded;
        state.deviceGroups = action.payload.data;
      })
      .addCase(getDeviceGroups.rejected, (state, action) => {
        state.deviceGroupsStatus = APIStatus.failed;
        state.deviceGroupsError = action.error;
      })
      .addCase(addDeviceGroup.pending, (state, action) => {
        state.addDeviceGroupStatus = APIStatus.loading;
      })
      .addCase(addDeviceGroup.fulfilled, (state, action) => {
        state.addDeviceGroupStatus = APIStatus.loaded;
        state.addDeviceGroupReceipt = action.payload.data;
      })
      .addCase(addDeviceGroup.rejected, (state, action) => {
        state.addDeviceGroupStatus = APIStatus.failed;
        state.addDeviceGroupError = action.error;
      })
      .addCase(updateDeviceGroup.pending, (state, action) => {
        state.updateDeviceGroupStatus = APIStatus.loading;
      })
      .addCase(updateDeviceGroup.fulfilled, (state, action) => {
        state.updateDeviceGroupStatus = APIStatus.loaded;
        state.updateDeviceGroupReceipt = action.payload.data;
      })
      .addCase(updateDeviceGroup.rejected, (state, action) => {
        state.updateDeviceGroupStatus = APIStatus.failed;
        state.updateDeviceGroupError = action.error;
      })
      .addCase(deleteDeviceGroup.pending, (state, action) => {
        state.deleteDeviceGroupStatus = APIStatus.loading;
      })
      .addCase(deleteDeviceGroup.fulfilled, (state, action) => {
        state.deleteDeviceGroupStatus = APIStatus.loaded;
        state.deleteDeviceGroupReceipt = 'Deleted Successfully';
      })
      .addCase(deleteDeviceGroup.rejected, (state, action) => {
        state.deleteDeviceGroupStatus = APIStatus.failed;
        state.deleteDeviceGroupError = action.error;
      });
            
  }
});

export const { reset, resetUpdateDeviceGroup, resetAddDeviceGroup, resetDeleteDeviceGroup } = deviceGroupsSlice.actions;

export default deviceGroupsSlice.reducer;