import { createTheme } from '@material-ui/core/styles';

import defaultTheme from './default';

const overrides = {
  typography: {
    fontFamily: 'Karla, sans-serif',
    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.64rem',
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      // fontSize: "1.385rem",
      //textTransform: "capitalize",
      fontFamily: 'Karla, sans-serif',
      //fontSize: "18px",
      fontWeight: 'bold',
      //marginTop : "1%",
      fontSize: '1.242rem', 
      //color: "#595959", 
      //lineHeight : "22px",
      //textTransform : "uppercase"
    },
    h6: {
      fontSize: '0.9rem',
    },
  },
};

const items = {
  default: createTheme({ ...defaultTheme, ...overrides }),
};
export default items;